@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?71388865');
  src: url('../font/fontello.eot?71388865#iefix') format('embedded-opentype'),
       url('../font/fontello.woff?71388865') format('woff'),
       url('../font/fontello.ttf?71388865') format('truetype'),
       url('../font/fontello.svg?71388865#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?71388865#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-home:before { content: '\e800'; } /* '' */
.icon-down-open:before { content: '\e801'; } /* '' */
.icon-doc-text-inv:before { content: '\e802'; } /* '' */
.icon-left-open:before { content: '\e803'; } /* '' */
.icon-right-open:before { content: '\e804'; } /* '' */
.icon-up-open:before { content: '\e805'; } /* '' */
.icon-left-circled:before { content: '\e806'; } /* '' */
.icon-up-circled:before { content: '\e807'; } /* '' */
.icon-right-circled:before { content: '\e808'; } /* '' */
.icon-down-circled:before { content: '\e809'; } /* '' */
.icon-search:before { content: '\e80a'; } /* '' */
.icon-star:before { content: '\e80b'; } /* '' */
.icon-ok:before { content: '\e80c'; } /* '' */
.icon-facebook:before { content: '\e80d'; } /* '' */
.icon-twitter:before { content: '\e80e'; } /* '' */
.icon-gplus:before { content: '\e80f'; } /* '' */
.icon-linkedin:before { content: '\e810'; } /* '' */
.icon-youtube:before { content: '\e811'; } /* '' */
.icon-list:before { content: '\e812'; } /* '' */
.icon-comment:before { content: '\e813'; } /* '' */
.icon-phone:before { content: '\e814'; } /* '' */
.icon-cancel:before { content: '\e815'; } /* '' */
.icon-down-circle:before { content: '\e816'; } /* '' */
.icon-left-circle:before { content: '\e817'; } /* '' */
.icon-right-circle:before { content: '\e818'; } /* '' */
.icon-up-circle:before { content: '\e819'; } /* '' */
.icon-dot:before { content: '\e81a'; } /* '' */
.icon-print:before { content: '\e81b'; } /* '' */