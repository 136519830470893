﻿@primary-color: #ee7421;
@primary-hover-color: #ffc08f;
@primary-active-color: #c55d0e;
@secondary-color: #00b09b;
@secondary-hover-color: #2ac3b1;
@secondary-active-color: #058979;
@tertiary-color: #eaeaea;
@tertiary-hover-color: #f4f4f4;
@tertiary-active-color: #d5d5d5;
@quaternary-color: #1a2727;
@quinary-color: #f5f5f5;
@senary-color: #ebb706;


.tom,
.zoekwrapper,
.zoekwrapper form,
.zoekwrapper form > button:before,
.zoekwrapper form > input[type=text],
header .header-top,
main .usps,
header .header-body .button-toggle-mobile-nav,
header .header-body .button-toggle-mobile-nav:before,
header .header-body a.logo,
footer > .centreer .infowrapper .adreswrapper > .logo > div,
#wrapper footer > div .infowrapper .zoekwrapper form > input[type=text],
#wrapper footer > div .infowrapper .zoekwrapper form > button,
.scrollToTop:before,
article > header > h1 a,
.form-control,
.media,
aside.filtering ul li a,
header .nav-tabs > li > a,
header .nav-tabs > li > a:hover,
.contact-wrapper > div > div > div ul li a,
.contact-wrapper > div > div > div ul li a:before,
.breadcrumbs ul li a, .breadcrumbs ul li a,
main .usps div > ul > li > a,
nav.sub-menu ul li a:hover,
.sectie-link li a,
#wrapper .dropdown-menu,
.content-wrapper header h2 a,
article.blog-card ul li a,
ul.socialmedia li a span,
body .js-san-autocomplete-results {
  -webkit-transition: all ease-out .5s;
  -moz-transition: all ease-out .5s;
  -o-transition: all ease-out .5s;
  transition: all ease-out .5s;
}

@font-face {
  font-family: 'open_sansregular';
  src: url('../font/OpenSans-Regular-webfont.eot');
  src: url('../font/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../font/OpenSans-Regular-webfont.woff') format('woff'), url('../font/OpenSans-Regular-webfont.ttf') format('truetype'), url('../font/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sansbold';
  src: url('../font/OpenSans-Bold-webfont.eot');
  src: url('../font/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../font/OpenSans-Bold-webfont.woff') format('woff'), url('../font/OpenSans-Bold-webfont.ttf') format('truetype'), url('../font/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'open_sanssemibold';
  src: url('../font/OpenSans-Semibold-webfont.eot');
  src: url('../font/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../font/OpenSans-Semibold-webfont.woff') format('woff'), url('../font/OpenSans-Semibold-webfont.ttf') format('truetype'), url('../font/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* algemene definities */
body,
html {
  margin: 0;
  padding: 0;
}

body,
a,
a:hover {
  font-family: 'open_sansregular', Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

body {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

@media (max-width: 768px) {
  body.showMobileNav {
    -webkit-transform: translateX(70%);
    -moz-transform: translateX(70%);
    -ms-transform: translateX(70%);
    -o-transform: translateX(70%);
    transform: translateX(70%);
    overflow-x: hidden;
  }
}

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
  color: #4d545e;
  font-family: open_sanssemibold;
}



h1, h3, h2 {
  text-transform: uppercase;
}

h1 {
  font-size: 26px;
}

article > header > h2,
article > header > h2 a,
article > header > h2 a:hover,
h2 {
  color: #4d545e;
  font-size: 20px;
}

article > header > h2 {
  margin-bottom: 20px;

  a,
  a:hover {
    text-decoration: none !important;
    font-family: open_sanssemibold;
  }

  a:hover {
    color: @secondary-color;
  }
}

article > header > h1,
h3 {
  margin-top: 20px;
  font-size: 16px;
}

h4 {
  color: #5c5c5c;
  font-size: 16px;
}

h5 {
  font-size: 16px;
}


#location-canvas {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

address {
  margin: 0;
  line-height: 23px;
}

.content-wrapper {


  a, a:hover {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }


  section {
    > ul {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }
  }
}


.center {
  text-align: center;

  > div {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  h2 {
    text-align: center;
    font-size: 24px;
  }
}

h5:first-child {
  margin-top: 0;
}

p {
  line-height: 23px;
}

.valign {
  display: table-cell;
  vertical-align: middle;
}

body #wrapper .resp-hidden {
  display: none;
}

ul {
  padding-left: 16px;
  line-height: 23px;
}

ol li {
  padding: 10px 0;
}

ul.reset-list,
ul.reset-list li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}


.list-full-width {
  display: table;
  width: 100%;

  > ul {
    display: table-row;

    > li {
      display: table-cell;
    }
  }
}

.centreer {
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 1170px) {
  .centreer {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-wrapper header {
  h2 {
    margin-bottom: 20px;
  }

  hr {
    margin-top: 0;
  }

  > ul.reset-list,
  > div.list-full-width {
    margin-bottom: 20px;
  }
}

hr {
  border-top: 1px solid #cfcfcf;
}

hr.large {
  margin-top: 30px;
  margin-bottom: 30px;
}

.block {
  background-color: #fff;
  border: solid 1px #e6e6e6;
  padding: 20px;
  zoom: 1;

  .visual {
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  header {

    hr {
      border-color: #f5f5f5;
      margin: 3px -20px 15px -20px;
    }
  }
}






#wrapper .noborder {
  border: 0;
}

#wrapper .nobackground {
  background-color: transparent;
}

#wrapper .nopadding {
  padding: 0;
}

#wrapper .notoppadding {
  padding-top: 0;
}

.block:before,
.block:after,
footer:before,
footer:after,
article:before,
article:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.block:after,
footer:after,
article:after {
  clear: both;
}

ul.two-clmns {
  > li {
    display: inline-block;
    width: 45%;
  }
}



ul.check-list,
ul.arrow-list {
  padding-left: 0;

  li {
    list-style-type: none;
  }

  li:before {
    font-family: fontello;
    margin-right: 10px;
    color: @secondary-color;
  }
}

ul.check-list {
  li:before {
    content: '\e80c';
  }
}

ul.arrow-list {
  li:before {
    content: '\e804';
  }
}



/* /algemene definities */

/* header */


#wrapper > header {
  position: relative;
  z-index: 2;
  background-color: #fff;

  .button-toggle-mobile-nav {
    display: none;
  }

  .offerte:hover {
    color: white
  }

  > nav {
    border-top: solid 1px #e6e6e6;
    margin-bottom: 1px;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;


    > div {
      > div {
        display: table;
        width: 100%;

        > ul {
          display: table-row;

          > li {
            display: table-cell;
            text-align: center;
            border-right: solid 1px #e6e6e6;
            outline: none;

            > a, > a:hover {
              font-family: open_sanssemibold;
              font-size: 15px;
              color: #4d545e;
              padding: 22px 20px 17px 20px;
              display: block;
              text-decoration: none;
              outline: none;
              border-bottom: solid 2px #fff;
            }

            > a:hover {
              color: @secondary-color;
            }
          }

          > li:first-child {
            border-left: solid 1px #e6e6e6;

            > a:before {
              font-family: fontello;
              content: "\e800";
              color: @secondary-color;
              font-size: 22px;
            }

            > a {

              > span {
                visibility: hidden;
                width: 10px;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  a.logo {
    width: 190px;
    height: 93px;
    display: inline-block;
    background-image: url(../img/logo-blomopleidingen.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 190px 93px;
    margin: 10px 0 15px 0;
    float: left;

    span {
      visibility: hidden;
    }
  }

  .header-top {
    background-color: @quinary-color;

    .actie {
      float: left;
      padding-top: 7px;
      font-size: 13px;
      margin-left: 10px;

      strong {
        color: @primary-color;
      }

      a {
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
      }
    }

    nav ul {
      padding-top: 0;

      li {
        display: inline-block;

        a, a:hover {
          font-weight: bold;
          font-size: 12px;
          padding: 7px 13px 8px 13px;
          display: inline-block;
          text-decoration: none;
        }

        a.button-inloggen {
          padding-left: 19px;
          padding-right: 30px;
          background-color: #d6d6d6;
          position: relative;
        }

        a.button-inloggen:after {
          font-family: fontello;
          content: "\e804";
          font-size: 14px;
          position: absolute;
          right: 17px;
          top: 8px;
        }
      }
    }
  }

  .tom {
    width: 360px;
    height: 118px;
    float: right;
    background: url(../img/header-sprite-tom.png) right bottom no-repeat;
    margin-left: 112px;

    > div {
      margin: 36px 0 5px 34px;

      > p {
        color: @secondary-color;
        font-family: open_sansbold;
        font-size: 16px;
        margin-bottom: 5px;
      }

      > a {
        color: #1b312d;
        outline: none;
      }

      > a:after {
        font-family: fontello;
        content: "\e808";
        font-size: 13px;
        margin-left: 2px;
      }
    }
  }

  .beoordeling-wrapper {
    display: block;

    > span {
      margin-left: 0;


      > span:before {
        font-size: 12px;
        top: 4px;
      }
    }
  }



  .zoekwrapper {
    margin-top: 16px;
    float: right;
    width: 360px;
  }
}

.close-mobile-nav {
  display: none;
}

@media (max-width: 768px) {
  body.showMobileNav .close-mobile-nav {
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}



.zoekwrapper {

  form {
    border: solid 1px #d5d5d5;
    border-radius: 6px;
    background-color: #fff;
    margin-top: 5px;
    position: relative;

    > input[type="text"] {
      width: 90%;
      text-indent: 5px;
      color: #303e48;
      line-height: 35px;
    }


    > button {
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;

      > span {
        visibility: hidden;
      }
    }

    > input[type=text],
    > button {
      height: 35px;
      border: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
      outline: none;
    }

    > button:before {
      font-family: fontello;
      content: "\e80a";
      color: #a5a8ab;
      font-size: 18px;
      margin-left: 8px;
      position: absolute;
      top: 9px;
      right: 10px;
      z-index: 1;
    }

    input,
    input[type=text]::-webkit-input-placeholder {
      color: #303e48;
    }
  }

  > ul {
    padding-top: 7px;

    li {
      display: inline-block;
      margin-right: 15px;

      a, a:hover {
        color: #303e48;
        text-decoration: none;
      }
    }
  }
}


.zoekwrapper:hover {
  form {
    border: solid 1px @secondary-color;

    > button:before {
      color: @secondary-color;
    }
  }
}



#wrapper > header a {
  -webkit-transition: all ease-out .2s;
  -moz-transition: all ease-out .2s;
  -o-transition: all ease-out .2s;
  transition: all ease-out .2s;
}

#wrapper > header a:hover {
  color: @secondary-color;
}


@media (max-width: 1090px) {
  #wrapper > header .tom {
    margin-left: 50px;
  }
}

@media (max-width: 1020px) {
  #wrapper > header {
    .zoekwrapper {
      width: 310px;
    }

    .zoekwrapper > form {
      width: 300px;
    }

    .tom {
      margin-left: 0;
      width: 300px;
      background-position: -24px bottom;

      > div {
        margin-left: 34px;
      }
    }
  }
}


@media (max-width: 991px) {
  #wrapper > header .header-top {
    .actie > span {
      display: none;
    }
  }
}

@media (max-width: 870px) {
  #wrapper > header {
    .tom {
      display: none;
    }

    a.logo {
      width: 40%;
    }

    > nav > div > div > ul > li > a,
    > nav > div > div > ul > li > a:hover {
      padding-left: 2px;
      padding-right: 2px;
    }

    .zoekwrapper {
      float: left;
      width: 60%;

      > form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  #wrapper > header > nav > div > div > ul > li:first-child > a > span {
    visibility: visible;
  }

  #wrapper > header {
    border-bottom: solid 1px #ccc;
  }
}




@media (min-width: 769px) {
  #wrapper > header > nav > div > div > ul > li.dropdown:hover {
    .dropdown-menu {
      display: block;
    }

    > a {
      border-bottom: solid 2px @secondary-color;
    }
  }
}


/* onder de 768 pixels de navigatie achter de knop */

@media (max-width: 768px) {
  body.showMobileNav {
    nav {

      > div {
        padding: 0;

        > div > ul {

          > li {
            text-align: left;
            border-bottom: solid 1px #ccc;

            a, a:hover {
              font-size: 18px;
              text-decoration: none;
              padding-left: 20px;
            }

            a:hover {
              background-color: #ececec;
            }
          }

          > li:first-child {
            a {
              span {
                visibility: visible;
              }
            }

            a:before {
              display: none;
            }
          }
        }
      }
    }
  }

  #wrapper .dropdown-menu {
    display: none !important;
  }
}

@media (max-width: 768px) {
  #wrapper > header,
  .centreer {
    position: static;
  }

  .resp-hidden {
    display: block;
  }

  #wrapper > header {
    .header-top {
      display: none;
    }

    > nav {
      background-color: #f4f7fa;
      border: solid 1px #dadcde;
      border-width: 0 1px 0 0;
      font-size: 18px;
      font-weight: 600;
      position: absolute;
      z-index: 30;
      top: 0;
      /*bottom: 0;*/
      left: 0;
      width: 70%;
      line-height: 40px;
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);

      > div {
        width: auto;

        > div,
        > div > ul,
        > div > ul > li {
          display: block;
          text-align: left;

          a, a:hover {
            padding-left: 20px;
          }
        }
      }
    }

    .header-body {
      .zoekwrapper {
        float: none;
        width: 100%;
        margin-bottom: 30px;

        .beoordeling-wrapper,
        ul {
          display: none;
        }

        form {
          > input[type=text],
          > button {
            height: 52px;
          }

          > input[type=text] {
            font-size: 18px;
            text-indent: 20px;
            text-indent: 10px;
          }

          > button:before {
            font-size: 26px;
            right: 15px;
            top: 16px;
          }
        }
      }

      a.logo {
        float: none;
        width: 220px;
        height: 108px;
        margin-top: 20px;
        background-size: 220px 108px;
      }

      .button-toggle-mobile-nav {
        background-color: #00a591;
        display: block;
        color: #fff;
        font-family: open_sansbold;
        font-size: 36px;
        position: absolute;
        top: 48px;
        right: 20px;
        padding: 30px 15px 30px 20px;
        border-radius: 12px;
        text-decoration: none;
      }

      .button-toggle-mobile-nav:before {
        font-family: fontello;
        content: '\e812';
        font-size: 36px;
        margin-right: 10px;
      }
    }
  }
}



@media (max-width: 479px) {
  #wrapper > header {
    .header-body {
      a.logo {
        float: none;
        width: 160px;
        height: 79px;
        margin-top: 20px;
        background-size: 160px 79px;
      }

      .button-toggle-mobile-nav {
        font-size: 16px;
        top: 52px;
        padding: 15px 10px 15px 10px;
        border-radius: 6px;
      }

      .button-toggle-mobile-nav:before {
        font-size: 16px;
        margin-right: 5px;
      }

      .zoekwrapper {
        form {
          > input[type="text"] {
            width: 80%;
            font-size: 15px;
          }

          > button {
            width: 20%;
          }
        }
      }
    }
  }
}

/* /header */



/* blok invert met background */

.block-invert {
  background: url(../img/invert-bg.jpg) 100% 100% no-repeat;
  background-size: 100% 100%;
  margin-top: 50px;
  padding: 20px;
  color: #fff;

  h2 {
    color: #fff;
  }

  h4 {
    color: @secondary-color;
    margin-bottom: 20px;
  }

  ul.check-list {
    li {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@media (max-width: 991px) {
  .block-invert {
    background: url(../img/pattern-bg.png);
  }
}
/* /blok invert met background */


/* beoordeling met sterren */
.beoordeling-wrapper {
  > span {
    display: inline-block;
    font-size: 16px;
  }

  .sterren {
    background-image: url(../img/stars.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    overflow-y: hidden;
  }
}

.beoordeling-wrapper.xlarge {
  .sterren {
    background-size: 96px 212px;
    width: 96px;
    height: 18px;
    margin: 0 3px;
    position: relative;
    top: 1px;
  }

  .aantalsterren0 {
    background-position: 0 0;
  }

  .aantalsterren05 {
    background-position: 0 -19px;
  }

  .aantalsterren1 {
    background-position: 0 -39px;
  }

  .aantalsterren15 {
    background-position: 0 -58px;
  }

  .aantalsterren2 {
    background-position: 0 -78px;
  }

  .aantalsterren25 {
    background-position: 0 -97px;
  }

  .aantalsterren3 {
    background-position: 0 -117px;
  }

  .aantalsterren35 {
    background-position: 0 -137px;
  }

  .aantalsterren4 {
    background-position: 0 -156px;
  }

  .aantalsterren45 {
    background-position: 0 -175px;
  }

  .aantalsterren5 {
    background-position: 0 -195px;
  }
}

.beoordeling-wrapper.normal {
  .sterren {
    background-size: 83px 184px;
    width: 83px;
    height: 15px;
    margin: 0 3px;
    position: relative;
    top: 1px;
  }

  .aantalsterren0 {
    background-position: 0 0;
  }

  .aantalsterren05 {
    background-position: 0 -17px;
  }

  .aantalsterren1 {
    background-position: 0 -34px;
  }

  .aantalsterren15 {
    background-position: 0 -51px;
  }

  .aantalsterren2 {
    background-position: 0 -68px;
  }

  .aantalsterren25 {
    background-position: 0 -85px;
  }

  .aantalsterren3 {
    background-position: 0 -102px;
  }

  .aantalsterren35 {
    background-position: 0 -119px;
  }

  .aantalsterren4 {
    background-position: 0 -136px;
  }

  .aantalsterren45 {
    background-position: 0 -153px;
  }

  .aantalsterren5 {
    background-position: 0 -170px;
  }
}

.beoordeling-wrapper.small {
  span {
    font-size: 13px;
  }

  .sterren {
    background-size: 62px 138px;
    width: 62px;
    height: 11px;
    margin: 0 3px;
    position: relative;
    top: 1px;
  }

  .aantalsterren0 {
    background-position: 0 0;
  }

  .aantalsterren05 {
    background-position: 0 -13px;
  }

  .aantalsterren1 {
    background-position: 0 -26px;
  }

  .aantalsterren15 {
    background-position: 0 -39px;
  }

  .aantalsterren2 {
    background-position: 0 -51px;
  }

  .aantalsterren25 {
    background-position: 0 -64px;
  }

  .aantalsterren3 {
    background-position: 0 -77px;
  }

  .aantalsterren35 {
    background-position: 0 -89px;
  }

  .aantalsterren4 {
    background-position: 0 -102px;
  }

  .aantalsterren45 {
    background-position: 0 -115px;
  }

  .aantalsterren5 {
    background-position: 0 -127px;
  }
}

h3 .beoordeling-wrapper {
  > span {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .beoordeling-cijfers.small {
    .col-sm-3 {
      width: 50% !important;
    }
  }
}

@media (max-width: 400px) {
  .beoordeling-cijfers.small {
    .col-sm-3 {
      width: 100% !important;
    }
  }
}
/* /beoordeling met sterren */

/* accordion */
.panel {
  background: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-default {
  border: 0;
}

.panel-default > .panel-heading,
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: 0;
}

.panel-default > .panel-heading {
  padding-left: 0;
  padding-bottom: 0;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  padding-left: 0;
  font-size: 13px;
  line-height: 23px;
  border-bottom: solid 1px #cfcfcf;
}


.panel-title {
  position: relative;

  a {
    padding-left: 20px;
  }
}

.panel-title a:before {
  font-family: fontello;
  content: "\e804";
  color: @secondary-color;
  position: absolute;
  left: 0;
  top: 1px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all ease-out .2s;
  -moz-transition: all ease-out .2s;
  -o-transition: all ease-out .2s;
  transition: all ease-out .2s;
}

.panel-title a.collapsed:before {
  content: "\e804";
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/* /accordion */

/* shortcuts */
.shortcuts {

  ul {
    float: left;


    > li {

      a, a:hover {
        white-space: nowrap;
        text-decoration: none;
      }

      a:hover {
        color: @secondary-color;
      }
    }
  }
}

@media (max-width: 1199px) {
  .shortcuts {

    ul {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .shortcuts {
    background: #fff;
    padding: 20px;
    border: solid 1px #e6e6e6;

    ul {
      padding-left: 0;
      list-style-type: none;

      li:before {
        content: '\e804';
        font-family: fontello;
        padding-right: 10px;
        color: @secondary-color;
      }
    }
  }
}
/* /shortcuts */


/* horizontale invoer */
.horizontal-invoer {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  h4 {
    line-height: 28px;
  }

  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  input[type=text] {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .horizontal-invoer {
    text-align: center;
  }

  /*[class*="col-"] {
        margin-top: 10px;
        margin-bottom: 10px;
        border:solid 1px #0f0;
    }*/

  button.pull-right {
    float: none !important;
  }
}
/* /mailing */


/* jaartallen historie */
.jaartal-wrapper {
  position: relative;
  height: 60px;
  margin: 0 0 60px 0;
  left: -3%;
  width: 106%;

  hr {
    margin: 0;
    margin-top: -31px;
    margin-left: 25px;
    width: 90%;
  }

  > div {

    > ul {
      position: relative;

      li {
        text-align: center;

        a {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background: #fff;
          border: solid 4px rgba(72,72,72,.15);
          display: inline-block;
          text-align: center;
          line-height: 50px;
          color: @secondary-color;
          font-size: 16px;
          font-family: open_sanssemibold;
          text-decoration: none;

          em {
            display: none;
          }
        }
      }
    }
  }
}

.jaartal-entry {
  padding-bottom: 40px;

  hr.line.bottom {
    display: none;
  }


  h3 {
    color: @secondary-color;
    font-size: 16px;
    font-family: open_sanssemibold;
    margin-top: 0;
  }

  hr.line.neg {
    -ms-transform: rotate(-19deg);
    -webkit-transform: rotate(-19deg);
    transform: rotate(-19deg);
  }

  hr.line.pos {
    -ms-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  .visual {

    hr.line {
      width: 38%;
      margin-top: 40px;
      margin-bottom: 0;
      border-color: #ccc;
    }
  }
}

@media (max-width: 768px) {
  .jaartal-wrapper {
    left: auto;
    width: 100%;
  }

  .jaartal-entry {
    padding-bottom: 20px;

    h3 {
      font-size: 26px;
      text-align: center;
    }

    .visual {


      hr.line {
        display: none;
      }
    }

    hr.bottom.line {
      display: block;
      width: 25%;
      position: relative;
      top: 15px;
      border-color: #ccc;
    }
  }
}


@media (max-width: 575px) {
  .jaartal-wrapper {
    hr {
      margin-top: -26px;
    }

    > div {
      > ul {
        > li {

          a {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            border-width: 3px;
            font-size: 12px;
            line-height: 45px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .jaartal-entry {

    hr.bottom.line {
      width: 50%;
    }
  }
}

@media (max-width: 428px) {
  .jaartal-wrapper {
    left: auto;
    width: 100%;

    hr {
      margin-top: -18px;
    }

    > div {
      > ul {
        > li {
          a {
            width: 35px;
            height: 35px;
            border-radius: 25px;
            border-width: 3px;
            font-size: 12px;
            line-height: 30px;

            span {
              display: none;
            }

            em {
              font-style: normal;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
/* /jaartallen historie */

/* aside kolom */
/* filtering navigator */
aside.filtering {

  .block {
    margin-bottom: 20px;

    div {


      hr {
        border-color: #f5f5f5;
        margin: 12px -20px 15px -20px;
      }

      > a, > a:hover {
        display: block;
        margin: 0;
        color: @secondary-color;
        font-family: open_sanssemibold;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        padding-right: 15px;
        text-decoration: none;
      }

      > a.arrow:before {
        font-family: fontello;
        content: '\e801';
        position: absolute;
        top: 3px;
        right: 0;
        font-size: 14px;
        height: 16px;
        -ms-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        -webkit-transition: all ease-out .4s;
        -moz-transition: all ease-out .4s;
        -o-transition: all ease-out .4s;
        transition: all ease-out .4s;
      }

      > a.collapsed:before {
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        top: 0;
      }
    }

    footer {
      margin-top: 15px;
    }
  }





  .selected-filter {
    h3:before {
      display: none;
    }

    ul {
      margin-bottom: 0;

      li {
        a, a:hover {
          border: solid 1px #dddddd;
          display: block;
          padding: 7px 25px 7px 10px;
          margin-top: 10px;
          border-radius: 2px;
          font-family: open_sansbold;
          position: relative;
          font-size: 12px;
        }

        a:after {
          font-family: fontello;
          content: "\e815";
          position: absolute;
          top: 7px;
          right: 10px;
          font-size: 14px;
          color: @secondary-color;
        }
      }

      li:first-child a {
        margin-top: 0;
      }
    }
  }

  ul {

    li {
      padding: 3px 0;

      a {
        font-family: open_sansbold;
        font-size: 13px;
        color: #4d545e;
        text-decoration: none;

        em {
          font-family: open_sansregular;
          font-style: normal;
          margin-left: 5px;
        }
      }

      a:hover {
        color: @secondary-color;
      }
    }

    li.selected {
      a {
        color: @secondary-color;
      }
    }
  }

  a.c2a-tertiary {

    span {
      display: block;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

@media (max-width:768px) {
  #mobile-filter-wrapper {
    background-color: #fff;
    margin: 0 15px 20px 15px;
    padding: 20px 10px;
    border: solid 1px #dddddd;

    > aside {
      margin: 0;
    }

    > aside.filtering {
      .block {
        border: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width:769px) {
  .button-toggle-filter {
    display: none;
  }
}

@media (max-width:768px) {
  .button-toggle-filter,
  .button-toggle-filter:hover {
    display: block;
    cursor: pointer;
    background-color: #00a591;
    font-size: 24px;
    color: #fff;
    font-family: open_sanssemibold;
    padding: 20px;
    text-decoration: none;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
    position: relative;
  }

  .button-toggle-filter:after {
    font-family: fontello;
    content: '\e816';
    font-size: 27px;
    color: #fff;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    -webkit-transition: all ease-out .2s;
    -moz-transition: all ease-out .2s;
    -o-transition: all ease-out .2s;
    transition: all ease-out .2s;
  }

  #mobile-filter-wrapper {
    display: none;
  }

  body.showFiltering {
    #mobile-filter-wrapper {
      display: block;
    }

    .button-toggle-filter,
    .button-toggle-filter:hover {
      margin-bottom: 0;
    }

    .button-toggle-filter:after {
      -ms-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  .producten {
    margin-top: 50px;
  }
}
/* /filtering navigator */

/* inzetten */
.inzet {
  margin-bottom: 20px;

  footer {
    a.c2a, a.c2a:hover {
      margin: 0;
    }
  }

  h5 {
    color: @secondary-color;
    font-family: open_sanssemibold;
    font-size: 16px;
    padding-bottom: 9px;
    margin-bottom: 0;
  }

  dl {
    margin-bottom: 0;

    dt {
      font-family: open_sanssemibold;
      font-size: 16px;
      color: #5c5c5c;
      margin-top: 20px;
    }

    dt:first-of-type {
      margin-top: 0;
    }

    dd ul {


      li {
      }
    }
  }

  .center {
    margin-top: 10px;
  }
}

.inzet ul.socialmedia {
  li {
    width: 49%;
  }
}

ul.socialmedia {
	li {
		display: inline-block;

		a {
			padding-left: 50px;
			position: relative;
			height: 50px;
			display: inline-block;
			line-height: 38px;
			color: #4d545e;
			font-size: 13px;
			font-family: open_sansbold;
		}

		span {
			position: absolute;
			left: 0;
			top: 0;
			background-color: #00b09b;
			width: 40px;
			height: 40px;
			border-radius: 20px;
			display: inline-block;
		}
	}

	li a span:before {
		font-family: fontello;
		font-size: 20px;
		color: #fff;
		position: absolute;
		left: 10px;
		top: 0;
	}

	li.linkedin a span:before {
		content: '\e810';
		left: 12px;
	}

	li.youtube a span:before {
		content: '\e811';
	}

	li.facebook a span:before {
		content: '\e80d';
		left: 15px;
		top: 1px;
	}

	li.twitter a span:before {
		content: '\e80e';
		top: 1px;
	}

	li.googleplus a span:before {
		content: '\e80f';
		left: 12px;
		top: 2px;
	}

	li.instagram a span:before {
		content: '\e80f';
		left: 12px;
		top: 2px;
	}

	li.print a span:before {
		content: '\e81b';
		left: 11px;
		top: 1px;
	}
}

article.deel-opleiding ul.socialmedia li {
  display: block;

  a {
    span {
      background-color: #ccc;
    }
  }

  a:hover span,
  a.active span {
    background-color: #00b09b;
  }
}

.inzet.inzet-heftig ul.socialmedia li {
  a {
    color: #fff;
  }

  span {
    background-color: #ffc600;
  }

  span:before {
    color: #404040;
  }
}

@media (max-width: 480px) {
  .inzet ul.socialmedia {
    li {
      width: 100%;
      display: block;
    }
  }
}

nav.categorieen-menu {
  .checkbox {
    padding: 8px 0;

    label {
      font-size: 12px;
    }

    input[type=checkbox] {
      margin-top: 2px;
    }
  }
}

.inzet-doelgroep {
  .visual {
    a > img {
      max-width: 100%;
      max-height: 100%;
    }

    margin-bottom: 18px;
  }

  a.c2a {
    margin-top: 0;
  }
}

.inzet-person {


  .row {
    margin-top: 25px;
  }

  .row:first-of-type {
    margin-top: 0;
  }

  .visual {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content {
    p {
      color: #888888;
      font-size: 13px;

      strong {
        font-family: open_sanssemibold;
        font-size: 16px;
        color: #5c5c5c;
      }
    }
  }
}

@media (max-width: 768px) {
  .inzet-person {
    .visual {
      width: 20%;
      float: left;
    }

    .content {
      width: 80%;
      float: left;
    }
  }
}


nav.sub-menu {


  ul {

    li {
      a,
      a:hover {
        display: block;
        color: #4d545e;
        font-size: 13px;
        font-family: open_sansbold;
        padding: 10px 25px 10px 0;
        position: relative;
        text-decoration: none;
      }

      a:hover {
        color: @secondary-color;
      }

      a:after {
        font-family: fontello;
        content: "\e804";
        color: @secondary-color;
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
  }

  a.c2a {
    margin-top: 15px;
  }
}

.inzet-heftig {
  .block {
    background: url(../img/pattern-bg.png);
    color: #fff;

    hr {
      margin-top: 12px;
      border-color: #616161;
    }
  }


  h5 {
    color: #ffc600;
  }

  nav.sub-menu {
    ul li {

      a {
        color: #fff;
      }

      a:after {
        color: #ffc600;
      }
    }
  }
}

a.card {
  border-radius: 2px;
  border: solid 1px #dddddd;
  padding: 15px 11px;
  display: block;
  margin-top: 11px;
  position: relative;
}

a.card:first-child {
  margin-top: 0;
}

a.card:after {
  font-family: fontello;
  content: "\e804";
  color: @secondary-color;
  position: absolute;
  right: 11px;
  top: 15px;
}
/* /inzetten */



@media (max-width: 767px) {
  aside {
    margin-top: 20px;
  }
}
/* /aside kolom */



/* page header */
.pageheader {
  margin-bottom: 38px;
  margin-top: 10px;

  .titelwrapper {
    float: left;
    width: 50%;

    header {
      height: 70px;

      h1 {
        margin: 0;
      }
    }

    .beoordeling-wrapper {
      padding-top: 20px;
      display: block;

      > span {
        font-family: open_sanssemibold;
      }
    }

    span.sterren span:before {
      top: 3px;
    }
  }
}

@media (max-width: 1023px) {
  .pageheader .titelwrapper > .valign {
    display: block;
    text-align: center;
    height: auto;
  }
}
/* contact blok in pageheader */
.contact-wrapper {
  float: right;

  > div {
    margin-bottom: 0;

    > img {
      display: block;
      float: left;
    }

    > div {
      padding: 10px;
      float: left;

      > p {
        color: @secondary-color;
        font-family: open_sansbold;
        font-size: 16px;
        margin: 0;
      }

      > div {
        ul {
          li {
            padding-left: 20px;

            a, a:hover {
              color: #1b312d;
              text-decoration: none;
            }

            a:hover:before,
            a:hover {
              color: @secondary-color;
            }

            a:before {
              margin-right: 6px;
              color: #1b312d;
              font-family: fontello;
            }
          }

          li:first-child {
            padding-left: 0;
          }

          li.icn-vraag a:before {
            content: "\e813";
          }

          li.icn-belterug a:before {
            content: "\e814";
          }

          li.icn-offerte a:before {
            content: "\e802";
          }
        }
      }
    }
  }
}


@media (max-width: 1023px) {
  .contact-wrapper {
    float: none;
    width: 100%;
    display: inline-block;


    div {
      text-align: center;

      img {
        display: none;
      }

      > div {
        float: none;
        display: inline-block;
        width: 100%;

        p,
        div {
          width: 50%;
          float: left;
        }
      }
    }
  }


  .pageheader .titelwrapper {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      font-size: 36px;
    }
  }
}

@media (max-width: 991px) {
  .contact-wrapper div > div {
    width: auto;

    p, div {
      width: auto;
      float: none;
    }
  }
}

@media (max-width: 768px) {
  .pageheader {
    padding-top: 40px;
    margin-top: 0;
  }

  .pageheader .titelwrapper {
    h1 {
      font-size: 26px;
    }
  }
}

@media (max-width: 480px) {
  .contact-wrapper {
    > div {
      > div {
        p > span,
        > div,
        > div ul,
        > div ul li {
          display: block;
        }
      }
    }
  }

  .pageheader .titelwrapper {
    h1 {
      font-size: 16px;
    }
  }
}
/* contact blok in pageheader */
/* /page header */


p.views {
  margin-bottom: 17px;
  text-align: right;
}


/* detail wrapper */

@media (max-width: 768px) {
  .detail-wrapper {
    .list-full-width,
    .list-full-width > ul,
    .list-full-width > ul > li {
      display: block;
    }
  }
}

/* /detail wrapper */



/* productdetailpagina */

.media {
  background: #fff;
  padding: 3px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
  margin-top: 20px;
  max-width: 100%;
  max-height: 100%;
}

iframe {
  border: 0;
}

.media:hover {
  opacity: .8;
}

.media.wrapleft {
  margin-right: 20px;
  float: left;
}

.media.wrapright {
  margin-left: 20px;
  float: right;
}

.media.wraptext {
  margin-bottom: 20px;
}



@media (min-width: 992px) {
  .media.wraptext {
    width: auto;
    max-width: 50%;
  }
}

@media (max-width: 991px) {
  .media.wraptext,
  .media.media-left,
  .media.media-right {
    float: none;
    margin: 0;
    display: inline-block;
    margin-bottom: 20px;
  }

  .media {
    margin-bottom: 20px;
  }
}
/* /productdetailpagina */

/* product specs op productdetailpagina */
.productdetail-specs-wrapper {
  background-color: @secondary-color;
  color: #fff;
  padding: 25px 0;

  header {
    padding: 0 25px;
    background-color: transparent;
    border: 0;

    h3 {
      font-size: 20px;
      font-family: open_sansbold;
      color: #fff;
      margin-top: 0;
      display: inline;
    }

    p {
      font-size: 13px;
      margin-top: 10px;
      display: inline;
    }
  }

  footer {
    background: none;
    padding: 0 25px;
    text-align: center;
    margin-top: 10px;
  }

  hr {
    border-color: #67c0b5;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  form {
    padding: 0 25px 10px 25px;

    > p {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .form-group {
      > label {
        color: #fff;
      }
    }

    .highlight {
      background-color: @secondary-active-color;
      padding: 10px;
      text-align: center;
      margin: 24px 0 22px 0;
      font-size: 13px;
    }
  }

  .c2a {
    width: 100%;
    min-width: 0;
  }
}


@media (max-width:768px) {
  .productdetail-specs-wrapper {
    margin-top: 20px;
  }
}
/* /product specs op productdetailpagina */






/* content kaartjes */
.negative-position-visual {
  margin-bottom: 20px;

  .visual {
    margin-top: -85px;
  }

  h2 {
    width: 50%;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .negative-position-visual {
    h2 {
      width: 100%;
      text-align: center;
    }

    .visual {
      text-align: center;
      margin-top: 20px;
    }
  }
}

article {
  margin-bottom: 40px;
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;

  footer {
    margin-top: 20px;

    .c2a, .c2a:hover {
      margin: 16px 0 10px 0;
    }

    .beoordeling-wrapper {
      display: block;
      text-align: center;
      height: 80px;

      > span {
        color: #a6a6a6;
        font-size: 11px;
      }
    }
  }

  > div {
    padding: 0;


    .visual {
      text-align: center;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .iconwrapper {
        position: absolute;
        z-index: 3;
        bottom: -21px;
        right: 10px;
        height: 40px;

        .icon {
          float: left;
        }
      }
    }
  }
}

.tab-content #tab-heftig article > .block {
  background: url(../img/pattern-bg.png);
}


article.light-card {
  header {
    height: 50px;
  }

  footer {
    a.c2a, a.c2a:hover {
      margin-top: 0;
    }
  }
}

article.product-card {
  header {
    h2 {
      color: #5c5c5c;
      font-size: 14px;
      font-family: open_sansbold;
      text-transform: none;
      height: 45px;
      margin-bottom: 0;
    }

    margin-bottom: 20px;
  }


  .visual {
    margin-left: -20px;
    margin-right: -20px;
  }

  footer {
    a.c2a {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
    }
  }
}

article > .horizontal-invoer {
  .c2a, .c2a:hover {
    margin: 0;
  }

  footer {
    margin-top: 0;
  }
}

p.prijs-wrapper {
  color: @secondary-color;
  font-size: 23px;
  width: 100%;
  text-align: left;

  span {
    color: #5c5c5c;
    font-family: open_sanssemibold;
    font-size: 13px;
  }
}

p.prijs-wrapper.opaanvraag {
  font-size: 18px;
  height: 27px;
  display: block;
}

p.prijs-wrapper.small {
  font-size: 18px;
}


@media (max-width: 480px) {
  article.product-card {
    width: 100%;
  }

  article {
    header {
      h2 {
        text-align: left;
      }

      .visual,
      footer {
        float: none;
        width: auto;
      }

      .visual {
        text-align: center;
        padding-left: 0;
      }
    }
  }
}



@media (min-width: 576px) {
  .absoluut-footer {
    footer {
      /*text-align: center;*/
      position: absolute;
      bottom: 15px;
      right: 40px;

      a.c2a {
        margin-top: 0;
      }
    }
  }
}


@media (max-width: 991px) {
  .absoluut-footer {
    .content {
      width: 100%;
    }

    footer {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  .absoluut-footer {

    div {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .hide-for-mobile {
    display: none;
  }
}
/* /producten */


/* beschrijving tekst + eventueel visual */


/* /beschrijving tekst + eventueel visual */



/* sortering */
.sortwrapper {
  width: 100%;
  margin-bottom: 40px;

  div {
    float: right;
  }

  .form-control {
    display: inline-block;
    width: auto;
  }
}

@media (max-width: 768px) {
  .sortwrapper > div {
    text-align: center;
    float: none;

    label, select {
      width: 100%;
      display: block;
    }

    label {
      margin-right: 0;
    }
  }
}
/* /sortering */

/* formulieren */

label {
  font-family: open_sansbold;
  font-size: 13px;
  color: #4d545e;
  margin-right: 30px;
}

.form-group {
  margin-bottom: 25px;
}

.form-control,
.form-control:focus {
  border-radius: 4px;
  border: solid 1px #d5d5d5;
  font-size: 13px;
  color: #4d545e;
  padding: 2px 10px;
  height: 30px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  /*-webkit-appearance: none;*/
}

textarea.form-control,
textarea.form-control:focus {
  height: auto;
}

.form-control:hover,
.form-control:focus {
  border: solid 1px @secondary-color;
}

label.radio-inline,
label.checkbox-inline {
  font-family: open_sansregular;
}


.form-control.input-validation-error,
.form-control.input-validation-error:focus,
.form-control.input-validation-error:hover {
  background-color: #ffdcc5;
  border-color: #ee7421;
  color: #c85304;
}

.form-control-feedback {
  width: 20px;
  height: 28px;
  right: 0;
}

[class*="col-"].has-feedback .form-control-feedback {
  right: 15px;
}

.form-control-feedback:before {
  font-family: fontello;
  content: '\e815';
  color: #c85304;
  font-size: 14px;
  position: absolute;
  height: 10px;
  line-height: 10px;
  top: 8px;
  right: 5px;
}


.has-feedback {
  > input.form-control {
    padding-right: 25px;
  }
}
/* /formulieren */


/* breadcrumbs */
.breadcrumbs {
  padding-top: 20px;

  ul {
    li {
      display: inline-block;

      a, a:hover {
        font-size: 11px;
        text-decoration: none;
      }

      a:hover {
        color: @secondary-color;
      }
    }

    li:before {
      content: "/";
      margin: 0 9px;
    }

    li:first-of-type:before {
      display: none;
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .breadcrumbs {
    display: none;
  }
}
/* /breadcrumbs */





/* main */
main {
  position: relative;
  z-index: 1;
  background-color: @quinary-color;
  padding-top: 36px;
  padding-bottom: 40px;
  min-height: 450px;


  .main-bg {
    width: 1360px;
    height: 8px;
    background: url(../img/main-bg.png) 50% 50%;
    position: absolute;
    left: 50%;
    margin-left: -680px;
    top: 0;
    z-index: 5;
  }
  /* usps */
  .usps {
    width: 1360px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -680px;
    height: 40px;
    background: url(../img/usps-bg.png) no-repeat center center;
    z-index: 4;

    div {
      padding: 0 30px;
      margin-top: 1px;

      > ul {
        > li {
          text-align: center;

          > h2 {
            margin: 0;
            font-size: 11px;
            line-height: 11px;
            text-transform: none;
            display: inline-block;

            > a, > a:hover {
              color: #4d545e;
              font-size: 11px;
              line-height: 36px;
              white-space: nowrap;
              text-decoration: none;
            }

            > a:hover {
              color: @secondary-color;
            }
          }
        }

        > li:before {
          font-family: fontello;
          content: '\e80c';
          color: @secondary-color;
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}


@media (max-width: 1363px) {
  main .main-bg,
  main .usps {
    width: 100%;
    left: 50%;
    margin-left: -50%;

    div {
      padding: 0;

      > ul {
        > li {
          > h2 {
            font-size: 9px;

            > a, > a:hover {
              /*font-size: 9px;*/
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  main .usps {
    width: 100%;
    left: 50%;
    margin-left: -50%;
  }

  main .usps div {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 1050px) {
  main .usps div {
    padding: 0;
  }
}

@media (max-width: 1023px) {
  main .usps {
    top: -40px;
  }
}

@media (max-width: 1000px) {
  main .usps {
    display: none;
  }
}

@media (max-width: 768px) {
  main .main-bg {
    display: none;
  }
}
/* /usps */




[class*="sectie-"] {
  margin-bottom: 0;

  > div {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h1, h2 {
    font-size: 30px;
    font-family: open_sansbold;
  }

  h2 {
    text-transform: uppercase;
    color: @secondary-color;
    text-align: center;
    width: 100%;
    display: block;
  }
}





/* grote plaat op homepagina */
.sectie-visual-wrapper {
  height: 377px;
  position: relative;
  overflow: hidden;
  margin-top: -36px;

  .carousel-inner {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -900px;
    width: 1800px;
    z-index: 1;
    overflow: visible;
    padding: 0;


    .item {
      top: 0px;

      .visual-desktop {
        position: absolute;
        top: 0;
        left: 0px;
        width: 1800px;
        height: 377px;
        z-index: 1;
      }

      .visual-mobile {
        display: none;
      }

      > div {
        padding: 0;

        .payoff {
          position: absolute;
          right: 200px;
          top: 70px;
          width: 380px;
          z-index: 4;

          h2 {
            font-family: open_sansbold;
            text-transform: uppercase;
            font-size: 40px;
            color: #4d545e;
            line-height: 40px;
            margin: 0 0 11px 0;
            text-align: left;

            span {
              color: @quaternary-color;
            }
          }

          p {
            font-size: 19px;
            color: #4d545e;
            line-height: 27px;
            margin-bottom: 23px;
          }
        }
      }
    }
  }

  .carousel-control {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-image: none !important;
    text-shadow: none;
    opacity: 1;
    background-color: rgba(255,255,255,.5);
    z-index: 2;
    filter: none;
  }

  .carousel-control.left {
    left: 20px;
  }

  .carousel-control.right {
    right: 20px;
  }

  .carousel-control:before {
    font-family: fontello;
    color: #00b09b;
    position: absolute;
    top: 23px;
    font-size: 35px;
    left: 50%;
  }

  .carousel-control.left:before {
    content: '\e803';
    margin-left: -15px;
  }

  .carousel-control.right:before {
    content: '\e804';
    margin-left: -10px;
  }
}



@media (max-width: 1170px) {
  .sectie-visual-wrapper {
    /*height: 100%;*/
    height: auto;

    .carousel-inner {
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      margin-left: 0;
      width: auto;

      .item {

        .visual-desktop {
          width: 100%;
          left: auto;
          top: auto;
          padding: 0;
          margin: 0;
          position: relative;
          height: auto;

          img {
            height: auto;
            margin-left: -25%;
            vertical-align: middle;
            max-width: 160%;
          }
        }

        > div {
          position: static;


          .payoff {
            right: 8%;
          }
        }
      }
    }
  }
}


@media (max-width: 1023px) {
  .sectie-visual-wrapper {
    .carousel-inner {

      .item {

        > div {
          .payoff {
            width: 300px;
            right: 13%;
            top: 60px;

            h1 {
              font-size: 24px;
              line-height: 24px;
            }

            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .sectie-visual-wrapper {
    border-bottom: solid 1px #cccccc;
    margin-top: 0;

    .carousel-inner {

      .item {


        .visual-desktop {
          display: none;
        }

        .visual-mobile {
          display: block;
          background-color: #e0e0e0;
          text-align: center;
          padding-top: 80px;

          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        > div {
          position: relative;
          background-color: #e0e0e0;
          padding-bottom: 84px;

          .payoff {
            position: relative;
            width: 100%;
            right: auto;
            top: auto;
            text-align: center;
            padding: 0 20px;

            h1 {
              font-size: 48px;
              line-height: 50px;
            }

            p {
              font-size: 20px;
              line-height: 32px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .sectie-visual-wrapper {
    .carousel-inner {
      .item {
        > div {
          .payoff {
            h1 {
              font-size: 30px;
              line-height: 30px;
            }

            p {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
/* /grote plaat op homepagina */



/* klanten */
.sectie-klanten {
  background: url(../img/pattern-bg.png);

  > div {
    background: url(../img/sectie-klanten-bg.png) no-repeat top center;

    header {
      h2 {
        color: @secondary-color;
      }
    }

    div.klant {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 0 20px;
      color: #fff;

      h2 {
        font-size: 18px;
        color: #fff;
        margin-top: 0;
      }

      p {
        font-size: 13px;
        line-height: 19px;
      }

      span.logo-wrapper {
        display: inline-block;
        text-align: center;

        > span {
          display: table-cell;
          vertical-align: middle;
          height: 150px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .sectie-klanten {
    > div {
      background: none;
    }
  }
}

@media (max-width: 768px) {
  .sectie-klanten {
    > div {
      > div.klant {
        display: block;
      }
    }
  }
}
/* /klanten */


/* download banner */
.sectie-download-banner {
  background-color: #00a591;
  background-image: url(../img/sectie-download-bg.png);
  background-position: 50% top;
  background-repeat: no-repeat;
  height: 65px;

  > div {
    text-align: center;
    padding: 0;

    > div {
      display: inline-block;
      margin: 0 auto;

      h2 {
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
        font-family: open_sansregular;
        line-height: 30px;
        margin-right: 40px;
        margin-bottom: 0;
        position: relative;
        top: 17px;
      }

      a.c2a {
        margin-left: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .sectie-download-banner {
    display: none;
  }
}
/* /download banner */



/* 4 categorieen */
.sectie-categorieen,
.categorieen {

  h1 {
    color: @quaternary-color;
  }

  a {
    margin-top: 75px;
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    height: 88px;
    position: relative;

    > span {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.categorieen a {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .sectie-categorieen {


    h2 {
      margin-bottom: 39px;
    }

    h3 {
      margin-left: -20px;
      margin-right: -20px;
    }

    a {
      font-size: 24px;
      line-height: 55px;
      height: auto;
      display: block;
      background-position: 40px 50%;
      margin-top: 0;
      text-align: left;
      padding: 20px 0;
      border-bottom: solid 1px #dddddd;
      font-family: open_sansbold;
      position: relative;

      > span {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        height: 55px;
        line-height: 55px;
        display: inline-block;
        padding-left: 20px;
      }
    }

    a:after {
      font-family: fontello;
      content: '\e804';
      font-size: 28px;
      position: absolute;
      top: 20px;
      right: 40px;
    }


    .row > div:first-child > .row > div:first-child > a {
      border-top: solid 1px #ccc;
    }

    .row > div:last-child > .row > div:last-child > a {
      border-bottom: solid 1px #ccc;
    }
  }
}

@media (max-width: 550px) {
  .sectie-categorieen {
    a {
      font-size: 18px;
      padding: 15px 0;

      img {
        /*width: 40px;*/
        height: 39px;
      }

      > span {
        padding-left: 10px;
      }
    }


    a:after {
      top: 15px;
      right: 20px;
    }
  }
}

@media (max-width: 400px) {
  .sectie-categorieen {

    a {
      font-size: 14px;
      padding: 10px 0;

      img {
      }
    }

    a:after {
      top: 10px;
      right: 0;
    }
  }
}
/* /4 categorieen */


/* beoordelingen homepagina */


.sectie-beoordelingen {
  h2 {
    font-size: 20px;
    color: #4d545e;
    width: 100%;

    > span {
      display: inline-block;

      span.sterren span:before {
        font-size: 20px;
        top: 4px;
      }
    }
  }
}


.beoordeling-cijfers {

  > div.row {
    margin-left: 0;
    margin-right: 0;
    display:flex;
    justify-content:center;

    > div {
      padding-left: 0;
      padding-right: 0;

      > div {
        display: table;
        width: 100%;

        > ul {
          display: table-row;

          > li {
            display: table-cell;
            text-align: center;
            min-width: 90px;

            > span {
              width: 70px;
              height: 70px;
              display: inline-block;
              background-color: #fff;
              border-radius: 35px;
              border: solid 4px #e3e3e3;
              font-size: 40px;
              font-family: open_sansbold;
              line-height: 60px;
              position: relative;
              color: #484848;

              > span {
                font-size: 20px;
                font-family: open_sansbold;
                line-height: 10px;
                position: relative;
                top: -14px;
              }
            }

            > p {
              font-size: 16px;
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }

  > p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 26px;
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .sectie-beoordelingen {
    h2 {
      text-align: center;

      > span {
        span {
          float: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sectie-beoordelingen {
    > div.centreer {
      background-color: #fff;
    }
  }
}

@media (max-width: 480px) {
  .sectie-beoordelingen {
    h2 {
      font-size: 30px;
    }
  }
}

@media (max-width: 1169px) and (min-width: 992px) {
  .beoordeling-cijfers {
    > div {
      > div {
        width: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .beoordeling-cijfers {
    > div {
      > div {
        width: 50%;
        float: left;
      }
    }
  }
}

@media (max-width: 450px) {
  .beoordeling-cijfers {
    > div {
      > div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {

  .beoordeling-cijfers {
    > div > div > div > ul > li {
      padding-bottom: 20px;
    }
  }
}
/* /beoordelingen homepagina */



/* blog */
.sectie-blog {
  background-color: #fff;

  header {
    h2 {
      color: #4d545e;
      text-align: left;
      font-size: 20px;
      font-family: open_sanssemibold;
    }
  }

  a.link-bullet:hover {
    text-decoration: underline;
  }
}

article.blog-card {
  position: relative;

  > .block {
    background-color: #f5f5f5;
    border-color: #cfcfcf;
    position: relative;
    z-index: 5;

    > p {
      font-style: italic;
    }
  }

  ul {
    li {
      display: inline-block;
      margin-right: 36px;

      a, a:hover {
        font-family: open_sansbold;
        font-size: 13px;
        text-decoration: none;
      }

      a:hover {
        color: @secondary-color;
      }

      a:before {
        font-family: fontello;
        margin-right: 5px;
      }
    }

    li.message a:before {
      content: '\e815';
    }

    li.alert a:before {
      content: '\e815';
    }
  }

  span {
    position: absolute;
    top: -135px;
    left: 20px;
    z-index: 4;
    display: inline-block;
    background-image: url(../img/blog-entry-line.png);
    background-repeat: no-repeat;
    background-position: 0 top;
    width: 1px;
    height: 155px;
  }

  span.first-reaction {
    top: -148px;
    left: -54px;
    width: 54px;
    height: 250px;
    background-position: 0 bottom;
  }

  footer {
    text-align: right;
  }
}

article.blog-card.level02 {
  margin-left: 85px;
}

article.blog-card.level03 {
  margin-left: 170px;
}

article.blog-card.level04 {
  margin-left: 255px;
}

article.blog-card.level05 {
  margin-left: 340px;
}

@media (max-width: 550px) {
  article.blog-card {
    > span {
      left: 10px;
    }

    > span.first-reaction {
      width: 10px;
      left: -10px;
    }

    footer ul li {
      display: block;
      margin: 0;
    }
  }

  article.blog-card.level02 {
    margin-left: 20px;
  }

  article.blog-card.level03 {
    margin-left: 40px;
  }

  article.blog-card.level04 {
    margin-left: 60px;
  }

  article.blog-card.level05 {
    margin-left: 80px;
  }
}
/* /blog */

.sectie-inzetten {
  .centreer {
    padding-bottom: 0px;
  }

  article {
    margin-bottom: 20px;
  }
}

.sectie-link {
  h2 {
    font-size: 20px;
    color: #4d545e;
    text-align: left;
  }

  h5 {
    font-size: 20px;
    border-bottom: solid 1px #cfcfcf;
    text-transform: uppercase;
    font-family: open_sansbold;
    padding-bottom: 16px;
    color: #4d545e;
  }

  li {
    position: relative;

    a, a:hover {
      font-family: open_sansbold;
      font-size: 13px;
      padding: 10px 15px 10px 0;
      display: block;
      color: #4d545e;
      text-decoration: none;
    }

    a:hover {
      color: @secondary-color;
    }
  }

  li:after {
    font-family: fontello;
    color: @secondary-color;
    content: '\e804';
    font-size: 13px;
    position: absolute;
    right: 0;
    top: 50%;
    line-height: 2px;
  }
}

@media (max-width: 767px) {
  .sectie-link {
    background: #fff;

    h1 {
      font-size: 30px;
      text-align: center;
    }

    > div.centreer {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      > .row {
        > .col-sm-6 {
          margin-top: 50px;
        }

        > .col-sm-6:first-of-type {
          margin-top: 0;
        }
      }
    }

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    [class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }

    h5 {
      text-align: center;
      font-size: 30px;
      padding-bottom: 25px;
      padding-top: 74px;
    }

    hr {
      margin-bottom: 0;
    }

    .col-sm-6:first-of-type h5 {
      padding-top: 0;
    }

    ul {
      background-color: @quinary-color;

      li {
        border-bottom: solid 1px #dddddd;

        a, a:hover {
          font-size: 18px;
          font-family: open_sansregular;
          padding: 26px 21px;
        }
      }

      li:after {
        right: 52px;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 479px) {
  .sectie-link {
    ul {
      li:after {
        right: 15px;
        font-size: 20px;
      }

      li {
        a, a:hover {
          font-size: 14px;
          padding: 20px 21px;
        }
      }
    }
  }
}


@media (max-width: 1023px) {
  main {
    padding-top: 0;
  }
}


.product-actie {
  .row,
  .form-group {
    margin: 0;

    > [class*="col-"] {
      padding: 0;
    }

    > label {
      text-align: left;
      line-height: 42px;
      white-space: nowrap;
    }

    input[type=text] {
      position: relative;
      top: 6px;
    }
  }

  a.c2a {
    width: 100%;
  }
}
/* /main */


/* buttons */
.c2a,
.c2a:hover {
  display: inline-block;
  border-radius: 2px;
  text-decoration: none !important;
  position: relative;
  border: 0;
  -webkit-transition: all ease-in-out .4s;
  -moz-transition: all ease-in-out .4s;
  -o-transition: all ease-in-out .4s;
  transition: all ease-in-out .4s;
  font-family: open_sansbold;
  font-size: 12px;
  cursor: pointer;
}


.c2a-xl,
.c2a-xl:hover {
  padding-top: 14px !important;
  padding-bottom: 13px !important;
  font-size: 16px;
}

.c2a-fullwidth,
.c2a-fullwidth:hover {
  width: 100%;
}

.c2a.center {
  text-align: center;
}



.c2a-primary,
.c2a-primary:hover {
  background-color: @primary-color;
  padding: 10px 15px;
  color: #fff;
}

.c2a-primary:hover {
  background-color: @primary-hover-color;
}

.c2a-primary:active {
  background-color: @primary-active-color;
}



.c2a-secondary,
.c2a-secondary:hover {
  background-color: @secondary-color;
  padding: 8px 15px;
  border: solid 1px #dddddd;
  color: #fff;
}

.c2a-secondary:hover {
  background-color: @secondary-hover-color;
}

.c2a-secondary:active {
  background-color: @secondary-active-color;
}


.c2a-tertiary,
.c2a-tertiary:hover {
  background-color: @tertiary-color;
  padding: 8px 15px;
  color: @quaternary-color;
}

.c2a-tertiary:hover {
  background-color: @tertiary-hover-color;
}

.c2a-tertiary:active {
  background-color: @tertiary-active-color;
}

.c2a-tertiary:after {
  color: @quaternary-color;
}





.c2a-quaternary,
.c2a-quaternary:hover {
  background-color: #eaeaea;
  border: solid 1px #dddddd;
  padding: 8px 15px;
  color: @quaternary-color;
}

.c2a-quaternary:after {
  color: @quaternary-color;
}

.c2a-senary,
.c2a-senary:hover {
  background-color: @senary-color;
  padding: 8px 15px;
  color: @quaternary-color;
}

.c2a-senary:after {
  color: @quaternary-color;
}



.modal .c2a.happy,
#wrapper .c2a.happy,
#wrapper .c2a.down {
  padding-right: 40px;
}

#wrapper .c2a.nothappy {
  padding-left: 40px;
}



.c2a:after {
  font-family: fontello;
  position: absolute;
  line-height: 2px;
  font-size: 17px;
  top: 50%;
  -webkit-transition: all ease-in-out .2s;
  -moz-transition: all ease-in-out .2s;
  -o-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.c2a.happy:after {
  content: "\e808";
  right: 16px;
}

.c2a.nothappy:after {
  content: "\e806";
  left: 16px;
}

.c2a.down:after {
  content: "\e816";
  right: 14px;
}

.c2a.c2a-xl.happy:hover:after {
  right: 6px;
}

.c2a.c2a-xl.nothappy:hover:after {
  left: 6px;
}

#wrapper .tab-content #tab-heftig .c2a.happy {
  background: #ebb706;
  color: #1a2727;
}


footer.buttonrow {
  margin-top: 0;

  a.c2a, a.c2a:hover {
    margin-left: 15px;
  }

  a.c2a:first-child, a.c2a:first-child:hover {
    margin-left: 0;
  }
}

footer.buttonrow.nofloat {
  a.c2a, a.c2a:hover {
    display: block;
  }
}

@media (max-width: 768px) {
  .buttonrow {
    margin-bottom: 30px;
  }
}


.link-bullet,
.link-bullet:hover {
  font-family: open_sansbold;
  font-size: 13px;
  text-decoration: none;
}

.link-bullet:after {
  font-family: fontello;
  content: '\e808';
  padding-left: 10px;
}

.link-bullet.secondary {
  color: @secondary-color;
}

.link-bullet.primary {
  color: @primary-color;
}


/* /buttons */


/* footer */
#wrapper > footer {
	background: url(../img/footer-pattern-bg.png);
	color: #fff;
	padding-bottom: 46px;

	> .centreer {

		h5 {
			color: #fff;
			font-size: 18px;
			font-weight: normal;
			text-transform: uppercase;
			margin-bottom: 25px;
			margin-top: 36px;
		}

		ul li a,
		ul li a:hover {
			color: #fff;
			font-size: 13px;
			line-height: 23px;
			text-decoration: none;
		}

		ul li a:hover {
			text-decoration: underline;
		}

		.locaties {
			img {
				margin-left: 30px;
			}

			a {
				color: #fff;
				font-size: 13px;
				margin-top: 20px;
				display: inline-block;
			}
		}


		.infowrapper {
			width: 100%;

			.zoekwrapper {
				> form {
					width: 100%;
					background-color: transparent;
					border: 0;

					input[type=text] {
						width: 75%;
						height: 30px;
						line-height: 30px;
						border-radius: 4px;
						border: 0;
						text-indent: 10px;
						float: left;
						outline: none;
						background-color: #fff;
					}


					button {
						background-color: @secondary-color;
						border: 0;
						border-radius: 4px;
						height: 30px;
						font-family: open_sansbold;
						color: @quaternary-color;
						float: left;
						font-size: 14px;
						outline: none;
						width: 23%;
						margin-left: 2%;

						span {
							color: @quaternary-color;
							visibility: visible;
						}
					}

					button:hover {
						background-color: @secondary-hover-color;
					}

					button:active {
						background-color: @secondary-active-color;
					}

					button:before {
						display: none;
					}

					input[type=text],
					input[type=text]::-webkit-input-placeholder {
						color: #303e48;
					}
				}

				p {
					font-size: 13px;
					margin-bottom: 13px;
				}
			}

			.adreswrapper {
				margin-top: 40px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;

				> .logo-nrto {
					order: 2;

					img {
						height: 80px;
					}
				}

				> .logo {
					order: 1;
					margin-bottom: 30px;
					width: 100%;
					text-align: center;

					> div {
						width: 100%;
						height: 79px;
						background-image: url(../img/logo-blomopleidingen-wit.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 162px 79px;
					}
				}

				> address {
					order: 3;
					line-height: 21px;
				}
			}

			@media(min-width:768px) {
				.adreswrapper {
					justify-content: flex-start;

					.logo-nrto {
						order: unset;
						margin-right: 15px;

						img {
							height: 60px;
						}
					}

					.logo {
						order: unset;
						margin-right: 30px;
						width: 160px;

						> div {
							width: 162px;
							background-position: 0 0;
						}
					}

					> address {
						order: unset;
					}
				}
			}
		}
	}
}

#wrapper > footer.subfooter {
	background-image: none;
	background-color: @secondary-color;
	padding-top: 13px;
	padding-bottom: 40px;

	> div {
		overflow-x: hidden;

		p {
			float: left;
		}

		ul {
			li {
				float: left;
				margin-left: 23px;

				span {
					visibility: hidden;
					width: 1px;
					height: 1px;
					display: inline-block;
				}
			}

			li a:before {
				font-family: fontello;
				font-size: 20px;
			}

			li.facebook a:before {
				content: '\e80d';
			}

			li.twitter a:before {
				content: '\e80e';
			}

			li.googleplus a:before {
				content: '\e80f';
				font-size: 18px;
			}

			li.linkedin a:before {
				content: '\e810';
			}

			li.instagram a {
				background-image: url(/Content/img/instagram.png);
				background-repeat: no-repeat;
				background-size: 18px 18px;
				width: 18px;
				height: 22px;
				display: inline-block;
				background-position: 0 3px;
			}

			li.youtube a:before {
				content: '\e811';
			}
		}
	}
}


	@media (max-width: 991px) {
		#wrapper {
			> footer {
				> .centreer {
					width: 85%;


					.footerlinks {
						width: auto;
						margin: 0 auto;
						float: none;
					}

					.adreswrapper {
						max-width: 350px;
						margin-left: auto;
						margin-right: auto;
					}

					.infowrapper {
						width: auto;
						margin: 0 auto;

						.zoekwrapper {
							border-bottom: solid 1px #ccc;
							border-top: solid 1px #ccc;
							padding-bottom: 80px;
							margin-top: 80px;

							p {
								font-size: 18px;
								line-height: 28px;
								text-align: center;
							}

							h5 {
								text-align: center;
								margin-top: 60px;
							}

							form {
								width: 100%;

								> input[type=text],
								> button {
									height: 52px;
									font-size: 18px;
								}

								> input[type=text] {
									width: 65%;
									line-height: 51px;
								}

								> button {
									width: 33%;
									margin-left: 2%;
								}
							}
						}
					}
				}
			}

			footer.subfooter {
				padding-top: 53px;

				> div {
					p, div {
						float: none !important;
					}

					p {
						font-size: 18px;
						text-align: center;
						line-height: 28px;
						width: 100%;
					}

					div {
						display: inline-block;
						margin-left: auto;
						margin-right: auto;
						text-align: center;
						width: 100%;
					}

					ul {
						display: inline-block;
						margin-top: 61px;

						li {
							margin-left: 30px;
						}

						li a:before {
							font-size: 40px;
						}

						li.googleplus a:before {
							font-size: 35px;
						}
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		#wrapper {
			footer {

				> div {
					width: 100%;

					.list {
						display: none;
					}

					.locaties {
						a {
							font-size: 24px;
							width: 100%;
							text-align: center;
							line-height: 30px;
						}

						.map {
							text-align: center;
						}
					}

					h5 {
						font-size: 30px;
						line-height: 42px;
						text-align: center;
					}

					.footerlinks {
						> .row {
							> div {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 480px) {
		#wrapper {
			> footer {
				.centreer {
					width: 100%;

					.row {
						margin-left: 0;
						margin-right: 0;
					}

					[class*="col-"] {
						padding-left: 0;
						padding-right: 0;
					}


					> .centreer {
						h5 {
							font-size: 24px;
						}

						.infowrapper {
							.zoekwrapper {
								form {

									> input[type=text] {
										width: 75%;
										height: 51px;
									}

									> button {
										width: 23%;
										height: 51px;

										> span {
											visibility: hidden;
										}
									}

									> button:before {
										position: relative;
										left: auto;
										top: auto;
										margin-left: 10px;
										font-family: fontello;
										content: '\e804';
										color: @quaternary-color;
										font-size: 30px;
										width: 100%;
										height: 51px;
										line-height: 51px;
										display: inline-block;
									}
								}
							}
						}
					}
				}
			}

			footer.subfooter {
				> div {
					> div {
						display: table;
						width: 100%;
						margin-top: 61px;

						> ul {
							display: table-row;

							li {
								float: none;
								display: table-cell;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 380px) {
		#wrapper footer > div .locaties h3 {
			font-size: 24px;
		}

		#wrapper footer > .centreer .infowrapper .adreswrapper > .logo > div {
			width: 123px;
			height: 60px;
			background-size: 123px 60px;
			margin-top: 19px;
		}
	}
	/* /footer */
	/* cols bij 768pixels op 100% 
@media (max-width: 768px) {
    main {
        .centreer {
            .row {
                .col-sm-4,
                .col-sm-8 {
                    width: 100%;
                }
            }
        }
    }
}
 /cols bij 768pixels op 100% */
	/* tabbladen */
	.tabbladen,
	.nav-tabs {
		border-bottom: 1px solid #ddd;

		> li {
			float: left;
			margin-bottom: -1px;
			position: relative;
			display: block;

			> a, > a:hover {
				margin-right: 12px;
				color: #5c5c5c;
				font-size: 12px;
				cursor: pointer;
				background-color: #eaeaea;
				border: 1px solid #ddd;
				padding: 6px 20px;
				margin-top: 6px;
				text-decoration: none;
				border-radius: 4px 4px 0 0;
				line-height: 1.42857143;
				display: block;
			}
		}

		> li:hover {
			a {
				background-color: #fff;
			}
		}

		> li.active {
			> a, > a:hover {
				border: 1px solid #ddd;
				font-size: 14px;
				font-family: open_sanssemibold;
				padding-top: 8px;
				margin-top: 1px;
				cursor: pointer;
				border-bottom: solid 1px #fff;
				background-color: #fff;
			}
		}

		#nav-tab-heftig.active a {
			background-color: #4d545e;
			color: #fff;
			border-bottom: solid 1px #4d545e;
		}
	}


	.tabbladen:before,
	.tabbladen:after {
		display: table;
		content: " ";
	}

	.tabbladen:after {
		clear: both;
	}

	.tab-content {
		.tab-pane {
			padding-top: 40px;

			> .row {
				margin-bottom: 50px;
			}
		}
	}

	.tab-content #tab-heftig {
		background-color: #4d545e;
		padding: 40px 10px 0 10px;
	}

	body #wrapper .tab-content #tab-heftig h4,
	body #wrapper .tab-content #tab-heftig .list-full-width > ul > li,
	body #wrapper .tab-content #tab-heftig p {
		color: #fff;
	}


	@media (max-width: 768px) {
		.tabbladen,
		.nav.nav-tabs {
			width: 100%;


			> li, > li.active {
				display: block;
				float: none;

				> a, > a:hover {
					border-bottom-color: transparent;
					font-size: 20px;
					border-radius: 0;
					margin-top: 6px;
					margin-right: 0;
					padding: 10px 20px;
					border-bottom: solid 1px #e6e6e6;
				}

				> a:after {
					position: absolute;
					right: 10px;
					top: 10px;
					font-family: fontello;
					content: "\e801";
				}


				> div {
					display: none;
				}
			}

			> li.active {
				position: relative;

				> a, > a:hover {
					color: @secondary-color;
				}

				> a:after {
					content: "\e805";
				}

				> div {
					display: block;
					background: #fff;
					border: 1px solid #ddd;
					border-top: 0;
					padding: 20px;
				}
			}
		}
	}

	@media (max-width: 480px) {
		.nav.nav-tabs > li.active > div {
			padding: 20px 10px;
		}
	}
	/* /tabbladen */
	/* modal */
	.modal-content {
		border-radius: 0;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.modal-backdrop {
		background-color: #1a2828;
	}

	.modal-backdrop.in {
		filter: alpha(opacity=70);
		opacity: .7;
	}

	.modal#tomKeuzeHulp {
		.modal-dialog {
			width: 975px;

			.modal-body {
				padding: 40px 30px;
				background: url(../img/sprite-tom-modal.png) no-repeat 0 50%;

				button.close {
					filter: alpha(opacity=100);
					opacity: 1;
					margin-top: 20px;
					outline: none;
				}

				button.close:before {
					font-family: fontello;
					content: '\e815';
					color: @secondary-color;
					font-size: 36px;
				}
			}

			h5 {
				color: #5b5b5b;
				font-size: 16px;
				font-family: open_sansbold;
				margin-top: 30px;
			}
		}
	}
	/* modal */
	/* zoekresultaten */
	.zoekresultaten {
		article {
			margin-bottom: 0;
		}
	}
	/* /zoekresultaten */
	/* naar top button */
	.scrollToTop,
	.scrollToTop:hover {
		display: none;
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 10000;
		width: 30px;
		height: 30px;
		text-decoration: none;

		> span {
			visibility: hidden;
		}
	}

	.scrollToTop:before {
		font-family: fontello;
		content: '\e807';
		font-size: 36px;
		color: #eaeaea;
	}

	.scrollToTop:hover:before {
		color: #d5d5d5;
	}
	/* /naar top button */
	/* dropdown menu */
	#wrapper .dropdown-menu {
		border-radius: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		border: solid 1px #e6e6e6;
		left: -1px;
		margin-top: 0;
		padding: 0;

		img {
			margin: 20px;
		}

		ul {
			display: table-row;
			padding: 0;

			li {
				display: table-cell;
			}

			li {
				ul {
					li {
						display: block;
					}
				}
			}

			li:first-child {
				border-right: solid 1px #f5f5f5;
			}

			> li > a {
				background-color: transparent;
				border-top: solid 1px #f5f5f5;
				padding: 6px 20px;
				display: inline-block;
				white-space: nowrap;
			}

			> li:first-child a {
				border-top: 0;
			}
		}
	}
	/* /dropdown menu */
	/* locaties */
	.locaties {
		span {
			display: inline-block;
			width: 120px;
		}

		span:first-child {
			width: 150px;
		}

		> span {
			color: #4d545e;
			font-size: 20px;
			margin-bottom: 10px;
		}

		> span:first-child {
			width: 167px;
		}

		.accordion-heading {
			padding: 3px 0;

			.accordion-toggle {
				text-decoration: none;
			}

			a.accordion-toggle:hover {
				span {
					color: #00b09b;
				}
			}

			.accordion-toggle:before {
				font-family: fontello;
				content: '\e804';
				margin-right: 10px;
				color: #00b09b;
			}
		}


		.accordion-inner {
			margin: 5px 0 20px 19px;
		}

		thead {
			td {
				color: #4d545e;
				font-size: 14px;
			}
		}

		table td {
			padding-right: 25px;
			line-height: 24px;
		}
	}

	.locaties > span:first-child {
		padding-left: 18px;
	}

	#wrapper .zoekwrapper:hover .js-san-autocomplete-results {
		border: solid 1px #00b09b;
		border-top: 0;
		border-radius: 6px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	#wrapper .js-san-autocomplete-results {
		width: 360px;
		top: 30px !important;
		border: solid 1px #d5d5d5;
		border-top: 0;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;

		> div {
			padding: 0 10px;

			h3 {
				font-size: 14px;
				margin-top: 25px;
				margin-bottom: 0;
			}
		}

		ul {
			padding-left: 0;
			margin-top: 0;

			li {
				margin: 0;
				padding: 5px 0 5px 0;
				list-style-type: none;
				position: relative;

				a,
				a:hover {
					text-decoration: none;
					padding-left: 15px;
				}

				a:before {
					display: none;
				}
			}

			li:before {
				position: absolute;
				top: 5px;
				left: 0;
				font-family: fontello;
				content: '\e804';
				margin-right: 10px;
				color: #00b09b;
			}
		}
	}


	.infowrapper .zoekwrapper:hover {
		form {
			border: none;
		}
	}


	.hbspt-form form {
		width: 100%;
		background-color: transparent;
		border: 0;


		&:hover {
			border: none;
		}

		div > label {
			display: none;
		}

		ul {
			position: absolute;
			top: 34px;
			list-style: none;
			padding-left: 0;

			label {
				color: #fff;
			}
		}

		input[type=email] {
			width: 75%;
			height: 30px;
			line-height: 30px;
			border-radius: 4px;
			border: 0;
			text-indent: 10px;
			float: left;
			outline: none;
			background-color: #fff;
		}


		input[type=submit] {
			background-color: @secondary-color;
			border: 0;
			border-radius: 4px;
			height: 30px;
			font-family: open_sansbold;
			color: @quaternary-color;
			float: left;
			font-size: 14px;
			outline: none;
			width: 23%;
			margin-left: 2%;

			span {
				color: @quaternary-color;
				visibility: visible;
			}
		}

		@media (max-width: 991px) {


			input[type=email],
			input[type=submit] {
				height: 52px;
				font-size: 18px;
			}

			input[type=email] {
				width: 65%;
				line-height: 51px;
			}

			input[type=submit] {
				width: 33%;
				margin-left: 2%;
			}

			ul {
				top: 60px;

				label {
					font-size: 16px;
				}
			}
		}
	}

	.hbspt-form {
		.submitted-message {
			font-weight: bold;
			font-size: 14px;
			color: @primary-color;

			@media (max-width: 991px) {
				font-size: 22px;
				line-height: 28px;
				text-align: center;
			}
		}
	}
