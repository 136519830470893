﻿.paging {
	zoom: 1;
	clear: both;
	margin: 40px 0;
	text-align: center;
}

	.paging:before, .paging:after {
		content: '';
		display: table;
	}

	.paging:after {
		clear: both;
	}

	.paging * {
		display: inline-block;
	}

	.paging a {
		text-decoration: none;
	}

		.paging a:hover {
			text-decoration: underline;
		}

	.paging ol {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.paging li {
		margin: 0;
		padding: 5px 0;
	}

		.paging a.to:active, .paging li > *:active, .paging li > *.active {
			outline: 0 !important;
			background-color: #efefef;
		}

		.paging li > * {
			padding: 0 5px;
		}

	.paging .to, .paging li {
		margin: 0 5px;
	}

	.paging .btn {
		float: none !important;
	}

@media (max-width: 41.4375em) {
	.paging {
		display: table;
		width: 100%;
	}

		.paging > * {
			display: table-cell;
		}

		.paging .back {
			text-align: left;
			white-space: nowrap;
		}

		.paging .forth {
			text-align: right;
			white-space: nowrap;
		}

		.paging ol {
			text-align: center;
		}
}

.paging .btn {
	float: none !important;
	padding-top: 3px;
	background-color: #ee7421;
	color: #fff;
}

.pading .btn :hover {
	background-color: #ffc08f;
}

ul.socialmedia li.nieuwsbrief a span:before {
  content: '\e802';
  top: 1px;
}

.product-card-smaller .beoordeling-wrapper.small span {
	font-size: 12px;
}

article.product-card header a {
	text-decoration: none;
}

	article.product-card header a:hover h2 {
		color: #00b09b;
	}

.btn.forward,
.btn.back {
	float: right;
	font-size: 14px;
	line-height: 30px;
}

.doelgroep-tabs {
	margin-bottom: 40px;
}

span.field-validation-error {
	font-weight: bold;
	color: #ee7421;
}

.modal#modalTerugbellen .modal-dialog {
	width: 700px;
}

	.modal#modalTerugbellen .modal-dialog .modal-header button.close {
		filter: alpha(opacity=100);
		opacity: 1;
		margin-bottom: 10px;
		outline: none;
	}

		.modal#modalTerugbellen .modal-dialog .modal-header button.close:before {
			font-family: fontello;
			content: '\e815';
			color: #00b09b;
			font-size: 36px;
		}

	.modal#modalTerugbellen .modal-dialog h5 {
		color: #5b5b5b;
		font-size: 16px;
		font-family: open_sansbold;
		margin-top: 30px;
	}

.breadcrumbs span {
	font-size: 11px;
	text-decoration: none;
}

/*.js-san-autocomplete-results {
	background: #fff;
	border: solid 1px #d1d1d1;
	z-index: 3;
	margin-left: -1px;
	margin-right: -1px;
	width: 383px;
}*/
.headersearch {
	position: relative;
}

.inzet .mpQuestionTable input[type=text] {
	width: 100%;
	border: 1px solid #d5d5d5;
	border-radius: 4px;
	box-shadow: none;
	color: #4d545e;
	font-size: 13px;
	height: 30px;
	outline: 0 none;
	padding: 2px 10px;
	margin-bottom: 0;
}

.inzet .mpQuestionTable .mpFormLabel label.descriptionLabel {
	margin-right: 1px;
}

.mpForm .mpFormField input[type=text] {
	width: 100%;
	border: 1px solid #d5d5d5;
	border-radius: 4px;
	box-shadow: none;
	color: #4d545e;
	font-size: 13px;
	height: 30px;
	outline: 0 none;
	padding: 2px 10px;
	margin-bottom: 0;
}

.mpForm .submitButton {
	background-color: #ee7421;
	padding: 14px 40px 13px 15px;
	color: white;
	font-size: 16px;
	display: inline-block;
	border-radius: 2px;
	text-decoration: none!important;
	position: relative;
	border: 0;
	-webkit-transition: all ease-in-out .4s;
	-moz-transition: all ease-in-out .4s;
	-o-transition: all ease-in-out .4s;
	transition: all ease-in-out .4s;
	font-family: open_sansbold;
	height: auto;
	cursor: pointer;
}

.js-san-autocomplete-results {
	width: 383px;
	/*z-index: 102;*/
	z-index: 993;
	min-height: 100px;
	background: #fff;
	-moz-box-shadow: 0px 1px 1px #7d7d7d;
	-webkit-box-shadow: 0px 1px 1px #7d7d7d;
	box-shadow: 0px 1px 1px #7d7d7d;
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=90, Color='#d7d7d7')";
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=200, Color='#d7d7d7');
	border: solid 1px #CCC \9;
	border-top: 0px \9;
}

	.js-san-autocomplete-results .js-suggestion-results,
	.js-san-autocomplete-results .js-product-results {
		padding: 20px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.js-san-autocomplete-results .js-suggestion-results {
		padding-bottom: 0px;
	}

	.js-san-autocomplete-results .js-product-results {
		padding-top: 0px;
	}

		.js-san-autocomplete-results .js-suggestion-results h3,
		.js-san-autocomplete-results .js-product-results h3 {
			/*font-size: 20px;*/
			line-height: 18px;
			font-weight: normal;
			/*font-family: aldrichregular;*/
			margin-bottom: 5px;
			margin-top: 8px;
		}

		.js-san-autocomplete-results .js-suggestion-results li,
		.js-san-autocomplete-results .js-product-results li {
			padding: 4px 0;
		}

			.js-san-autocomplete-results .js-suggestion-results li > a,
			.js-san-autocomplete-results .js-product-results li > a {
				/*background: url(/Content/img/arrow-happy.png) no-repeat 0 5px;*/
				display: block;
			}

				.js-san-autocomplete-results .js-suggestion-results li > a > strong,
				.js-san-autocomplete-results .js-product-results li > a > strong {
					color: #00b09b;
				}

				.js-san-autocomplete-results .js-suggestion-results li > a:before,
				.js-san-autocomplete-results .js-product-results li > a:before {
					font-family: fontello;
					content: '\e804';
					margin-right: 10px;
					color: #00b09b;
				}

				.js-san-autocomplete-results .js-suggestion-results li > a:hover,
				.js-san-autocomplete-results .js-suggestion-results li > a:hover > strong .js-san-autocomplete-results .js-product-results li > a:hover,
				.js-san-autocomplete-results .js-product-results li > a:hover > strong {
					color: #f09658;
				}

				.js-san-autocomplete-results .js-suggestion-results li > a:hover,
				.js-san-autocomplete-results .js-product-results li > a:hover {
					/*background: url(/Content/img/arrow-happy-ov.png) no-repeat 0 5px;*/
				}

.blog-tabs {
	margin-bottom: 0px;
}

.mpForm {
	padding-left: 0px;
}

	.mpForm > ul {
		list-style-type: none;
		padding-left: 5px;
	}

	.mpForm .mpFormField ul {
		list-style-type: none;
		padding-left: 0px;
	}

	.mpForm label.error {
		font-weight: bold;
		color: #ee7421;
	}

	.mpForm .mpQuestionTable {
		clear: left;
		margin-bottom: 25px;
	}

	.mpForm .mpFormField #field1067 ul {
		clear: both;
	}

	.mpForm .mpFormField #field1067 li {
		float: left;
	}

	.mpForm .mpQuestionTable.error input {
		background-color: #ffdcc5;
		border-color: #ee7421;
		color: #c85304;
	}

	.mpForm .mpFormField ul li label {
		font-family: 'open_sansregular', Arial, sans-serif;
		padding-left: 3px;
	}

	.mpForm .submitButton {
	}

.heftig {
	background-color: #4d545e;
	padding: 20px 10px 0 10px;
	color: white;
}

	.heftig .blog-bericht p img {
		max-width: 100%;
		max-height: 100%;
	}

	.heftig h2 {
		color: white;
	}

	.heftig h4, .heftig label {
		color: white;
	}

	.heftig .blog-bericht {
		background: url(../img/pattern-bg.png);
	}

	.heftig .blog-bericht a {
		color: #ebb706;
	}

	.heftig .blog-card .block {
		background: url(../img/pattern-bg.png);
	}

	.heftig .blog-bericht {
		margin-bottom: 30px;
	}

	.heftig .blog-card a {
		color: white;
	}


	.heftig .c2a.happy {
		background: #ebb706;
		color: #1a2727;
	}

@media (max-width: 480px) {
	.scrollToTop, .scrollToTop:hover {
		bottom: 90px;
	}
}

.input-error {
	background-color: #ffdcc5 !important;
	border-color: #ee7421 !important;
	color: #c85304 !important;
}

.vestigingen-overzicht h4 {
	font-size:12px;
}

.werkenbanner {
	text-align: center;
}
.werkenbanner img {
	width: 100%;
	max-width: 800px;
}
.logisticabanner {
	text-align: center;
}
.logisticabanner img {
	/*width: 100%;
	max-width: 800px;*/
}

#tomKeuzeHulp .radio-inline.keuzehulp {
	margin-left: 0px;
	width: 20%;
}

.hs-cta-img {
  width: 100%;
  height: auto;
}